import * as bootstrap from 'bootstrap';

/*!
 * Color mode toggler for Bootstrap's docs (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under the Creative Commons Attribution 3.0 Unported License.
 */

(() => {
    'use strict';

    const getStoredTheme = () => localStorage.getItem('theme');
    const setStoredTheme = (theme) => localStorage.setItem('theme', theme);

    const getPreferredTheme = () => {
        const storedTheme = getStoredTheme();
        if (storedTheme) {
            return storedTheme;
        }

        return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    };

    const setTheme = (theme) => {
        if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            document.documentElement.setAttribute('data-bs-theme', 'dark');
        } else {
            document.documentElement.setAttribute('data-bs-theme', theme);
        }
    };

    setTheme(getPreferredTheme());

    const showActiveTheme = (theme, focus = false) => {
        const themeSwitcher = document.querySelector('#bd-theme');

        if (!themeSwitcher) {
            return;
        }

        const themeSwitcherText = document.querySelector('#bd-theme-text');
        const activeThemeIcon = document.querySelector('.theme-icon-active use');
        const btnToActive = document.querySelector(`[data-bs-theme-value="${theme}"]`);
        const svgOfActiveBtn = btnToActive.querySelector('svg use').getAttribute('href');

        document.querySelectorAll('[data-bs-theme-value]').forEach((element) => {
            element.classList.remove('active');
            element.setAttribute('aria-pressed', 'false');
        });

        btnToActive.classList.add('active');
        btnToActive.setAttribute('aria-pressed', 'true');
        activeThemeIcon.setAttribute('href', svgOfActiveBtn);
        const themeSwitcherLabel = `${themeSwitcherText.textContent} (${btnToActive.dataset.bsThemeValue})`;
        themeSwitcher.setAttribute('aria-label', themeSwitcherLabel);

        if (focus) {
            themeSwitcher.focus();
        }
    };

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
        const storedTheme = getStoredTheme();
        if (storedTheme !== 'light' && storedTheme !== 'dark') {
            setTheme(getPreferredTheme());
        }
    });

    window.addEventListener('DOMContentLoaded', () => {
        showActiveTheme(getPreferredTheme());

        document.querySelectorAll('[data-bs-theme-value]').forEach((toggle) => {
            toggle.addEventListener('click', () => {
                const theme = toggle.getAttribute('data-bs-theme-value');
                setStoredTheme(theme);
                setTheme(theme);
                showActiveTheme(theme, true);
            });
        });
    });
})();

/* global bootstrap: false */

(() => {
    'use strict';

    if (location.pathname !== '/_theme') {
        return;
    }

    // Tooltip and popover demos
    document.querySelectorAll('.tooltip-demo').forEach((tooltip) => {
        new bootstrap.Tooltip(tooltip, {
            selector: '[data-bs-toggle="tooltip"]',
        });
    });

    document.querySelectorAll('[data-bs-toggle="popover"]').forEach((popover) => {
        new bootstrap.Popover(popover);
    });

    document.querySelectorAll('.toast').forEach((toastNode) => {
        const toast = new bootstrap.Toast(toastNode, {
            autohide: false,
        });

        toast.show();
    });

    // Disable empty links and submit buttons
    document.querySelectorAll('[href="#"], [type="submit"]').forEach((link) => {
        link.addEventListener('click', (event) => {
            event.preventDefault();
        });
    });

    function setActiveItem() {
        const { hash } = window.location;

        if (hash === '') {
            return;
        }

        const link = document.querySelector(`.bd-aside a[href="${hash}"]`);

        if (!link) {
            return;
        }

        const active = document.querySelector('.bd-aside .active');
        const parent = link.parentNode.parentNode.previousElementSibling;

        link.classList.add('active');

        if (parent.classList.contains('collapsed')) {
            parent.click();
        }

        if (!active) {
            return;
        }

        const expanded = active.parentNode.parentNode.previousElementSibling;

        active.classList.remove('active');

        if (expanded && parent !== expanded) {
            expanded.click();
        }
    }

    setActiveItem();
    window.addEventListener('hashchange', setActiveItem);
})();
